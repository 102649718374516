export function setCollectionsViewState(state) {
  // if funfacts in params, user directly navigated
  // via QR code. Remove query so that "back" navigation
  // goes back to the selected collectionsViewState, and does
  // not accidentally select fun facts b/c of param
  if (window.location.search.includes('funfacts')) {
    window.history.replaceState({}, document.title, '/collection');
  }
  this.setState({ collectionsViewState: state });
}

export function toggleForFamiliesExperienceViewState() {
  this.setState({
    forFamiliesExperienceIsGrid: !this.state.forFamiliesExperienceIsGrid,
  });
}
