/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import Header, { headerVars } from "./Header";
import GlobalStyles from "./GlobalStyles";
import ButtonNavToggle from "./ButtonNavToggle";
import NavOverlay from "./NavOverlay";
import NavMenu from "./NavMenu";
import styled from "@emotion/styled";
import Player from "./Player";
import { StoreContext } from "../store";
import ParentRouteHandler from "./ParentRouteHandler";
import ManageScroll from "./ManageScroll";
import { SessionStorageService } from "../services/session-storage";

const LayoutStyles = styled.main`
  padding: 10px;
  max-width: 768px;
  margin: 0 auto;
  overflow: hidden;
`;

const PlayerWrapper = styled.div`
  height: ${(props) => (props.trackUrlNotNull ? "120px" : "0")};
`;

const Spacer = styled.div`
  height: ${headerVars.height};
`;

const Layout = (props) => {
  const [navOpen, setNavOpen] = useState(false);
  const {
    audioDetails,
    exploredArt,
    setExploredArt,
    scavengerHunt,
    setScavengerState,
  } = useContext(StoreContext);

  const { url, isPlaying, title, subTitle } = audioDetails;

  useEffect(() => {
    // if explored art in local storage but not yet saved in app state
    if (!exploredArt && SessionStorageService.getValue("broad_exploredArt")) {
      // set initial state with saved items
      setExploredArt(SessionStorageService.getValue("broad_exploredArt"));
    } else {
      // or set initial state with object if no local storage
      SessionStorageService.setValue("broad_exploredArt", {});
    }

    // if scavenger hunt is in local storage but not saved in app state
    // no need to set initial state with object if no local storage.
    // getting the initial state from initialState.js file
    if (SessionStorageService.getValue("broad_scavengerHunt")) {
      // set initial state with saved items
      setScavengerState(SessionStorageService.getValue("broad_scavengerHunt"));
    }
  }, []);

  // when nav item is clicked
  // 1. close nav menu
  // 2. set the back button to say "back"
  const onNavItemClicked = () => {
    setNavOpen(!navOpen);
  };

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={(data) => (
        <div>
          <GlobalStyles location={props.location} />
          {/* Override in children if parent route other than home*/}
          <ParentRouteHandler />
          {/* Saves scroll positions */}
          <ManageScroll />

          <Spacer />
          <Header
            siteTitle={data.site.siteMetadata.title}
            location={props.location}
          />
          <ButtonNavToggle
            handler={() => setNavOpen(!navOpen)}
            navOpen={navOpen}
          />
          <NavOverlay navOpen={navOpen}>
            <NavMenu handler={onNavItemClicked} />
          </NavOverlay>

          <LayoutStyles>{props.children}</LayoutStyles>

          <PlayerWrapper trackUrlNotNull={url}>
            {url && (
              <Player
                url={url}
                title={title}
                subTitle={subTitle}
                isPlaying={isPlaying}
              />
            )}
          </PlayerWrapper>
        </div>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
