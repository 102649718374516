export class SessionStorageService {
  static setValue(key, value) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  static getValue(key) {
    let data = sessionStorage.getItem(key);

    if (data === null) {
      return null;
    }

    try {
      data = JSON.parse(data);
    } catch (e) {
      console.log(e);
      return null;
    }

    return data;
  }

  static clearValue(key) {
    sessionStorage.removeItem(key);
  }

  static clearExcept(keep) {
    Object.keys(sessionStorage)
      .filter(key => key !== keep)
      .forEach(key => sessionStorage.removeItem(key));
  }

  static clearAll() {
    sessionStorage.clear();
  }
}
