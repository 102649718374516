import { closeNavBar, showNavBar, toggleNavBar } from "./showNavBar";
import { toggleToursView } from "./toursView";
import { clearCurrentTrack, playTrack, pauseTrack, onAudioInit } from "./audio";
import { setMap } from "./map";
import { setParentRoute } from "./routeTracker";
import { searchCollections } from "./search";
import {
  setCollectionsViewState,
  toggleForFamiliesExperienceViewState,
} from "./collectionsView";
import { markArtExplored, setExploredArt } from "./exploreArt";
import { markScavenger, setScavengerState } from "./scavengerHunt";
import { closeAlert, showAlert, toggleAlert } from "./visitorAlert";

const storeMethods = {
  showNavBar,
  closeNavBar,
  toggleNavBar,
  toggleToursView,
  clearCurrentTrack,
  playTrack,
  pauseTrack,
  onAudioInit,
  setMap,
  setParentRoute,
  searchCollections,
  setCollectionsViewState,
  toggleForFamiliesExperienceViewState,
  markArtExplored,
  setExploredArt,
  markScavenger,
  setScavengerState,
  closeAlert,
  showAlert,
  toggleAlert,
};

export default storeMethods;
