export default {
  navBarOpen: false,
  isGrid: true,
  collectionsViewState: "grid",
  currentMap: { langCode: "en", level: 0 },
  visitorAlert: true,
  audioDetails: {
    url: null,
    isPlaying: false,
    title: null,
    subtitle: null,
  },
  parentRouteDetails: {
    path: null,
    title: null, // if this === 'back', it will use browser's backwards navigation
  },
  collectionsSearchTerm: "",
  forFamiliesExperienceIsGrid: true,
  // since intitial state for exploredArt relies on
  // localStorage, and localStorage isn't available in
  // gatsby build, setting in Layout.js
  exploredArt: null,
  scavengerHunt: {
    items: [
      {
        id: 0,
        text: "Artwork with all three primary colors (red, yellow, blue)",
        checked: false,
      },
      {
        id: 1,
        text: "Artwork with your favorite color",
        checked: false,
      },
      {
        id: 2,
        text: "Artwork that is black and white",
        checked: false,
      },
      {
        id: 3,
        text: "Artwork that includes people",
        checked: false,
      },
      {
        id: 4,
        text: "Artwork that includes an animal",
        checked: false,
      },
      {
        id: 5,
        text: "Painting with multiple shapes",
        checked: false,
      },
      {
        id: 6,
        text: "Painting with wavy lines",
        checked: false,
      },
      {
        id: 7,
        text: "Abstract painting",
        checked: false,
      },
      {
        id: 8,
        text: "Artwork from another country",
        checked: false,
      },
      {
        id: 9,
        text: "Artwork made of metal",
        checked: false,
      },
      {
        id: 10,
        text: "Artwork made of wood",
        checked: false,
      },
      {
        id: 11,
        text: "Artwork that is larger than you",
        checked: false,
      },
      {
        id: 12,
        text: "Artwork that makes you sad",
        checked: false,
      },
      {
        id: 13,
        text: "Artwork that makes you laugh",
        checked: false,
      },
      {
        id: 14,
        text: "Photograph",
        checked: false,
      },
      {
        id: 15,
        text: "Type of art you’ve never seen before",
        checked: false,
      },
      {
        id: 16,
        text: "Type of art that is familiar to you",
        checked: false,
      },
      {
        id: 17,
        text: "Artwork that you would make",
        checked: false,
      },
      {
        id: 18,
        text: "Artwork that you would give to a friend",
        checked: false,
      },
    ],
  },
};
