import React from "react";
import { Link } from "gatsby";
import { StaticQuery, graphql } from "gatsby";
import styled from "@emotion/styled";
import { colors, fonts } from "../utilities/themeVars";
import PropTypes from "prop-types";
import { OutboundLink } from "gatsby-plugin-google-gtag";

const NavMenuButton = styled.button`
  a {
    display: inline-block;
    font-size: 38px;
    margin: 0 0 15px;
    text-align: left;
    font-weight: bold;
    font-family: ${fonts.secondary};
    color: ${colors.white};
    text-decoration: none;
    color: ${colors.white};
  }
`;

const NavMenu = ({ handler }) => {
  const handleClick = (link) => {
    handler();
  };
  return (
    <StaticQuery
      query={query}
      render={({ allBroadMenu }) => {
        const { edges } = allBroadMenu;

        return (
          <div>
            {edges.map((edge) => {
              const { node } = edge;
              const { id, label, link } = node;
              return (
                link && (
                  <div key={id}>
                    <NavMenuButton onClick={() => handleClick(link)}>
                      {link.includes("http") ? (
                        <OutboundLink href={link} target={"_blank"}>
                          {label}
                        </OutboundLink>
                      ) : (
                        <Link to={`${link}?from-nav=1`}>{label}</Link>
                      )}
                    </NavMenuButton>
                  </div>
                )
              );
            })}
          </div>
        );
      }}
    />
  );
};

const query = graphql`
  {
    allBroadMenu {
      edges {
        node {
          id
          link
          label
        }
      }
    }
  }
`;

NavMenu.propTypes = {
  handler: PropTypes.func,
};

export default NavMenu;
