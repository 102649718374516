import React, { useContext } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { headerVars } from "./Header";
import { colors } from "../utilities/themeVars";
import { graphql, useStaticQuery } from "gatsby";
import { StoreContext } from "../store";

const ButtonNavToggle = ({ handler, navOpen }) => {
  const { parentRouteDetails, visitorAlert } = useContext(StoreContext);

  const { alerts } = useStaticQuery(
    graphql`
      query {
        alerts: allNodeVisitorAlert {
          nodes {
            title
          }
        }
      }
    `
  );

  const Button = styled.button`
    position: fixed;
    top: ${alerts.nodes.length && visitorAlert && !navOpen ? "26px;" : "0;"}
    right: 10px;
    background: none;
    height: ${headerVars.height};
    outline: none;
    box-shadow: none;
    border: none;
    width: 32px;
    z-index: ${headerVars.zIndex.toggle};

    .lines {
      position: relative;
      width: 16px;
      height: 16px;
      span {
        position: absolute;
        display: block;
        width: 16px;
        height: 2px;
        background: ${colors.text};
        &:nth-of-type(1),
        &:nth-of-type(2),
        &:nth-of-type(3) {
          transition: all 0.2s 0.2s ease;
          left: 0;
        }

        &:nth-of-type(1) {
          top: 0;
        }
        &:nth-of-type(2) {
          top: 6px;
        }
        &:nth-of-type(3) {
          top: 12px;
          width: 10px;
          margin-bottom: 0;
        }

        &:nth-of-type(4),
        &:nth-of-type(5) {
          opacity: 0;
        }
        &:nth-of-type(4) {
          top: -10px;
          left: -10px;
          transform: rotate(45deg);
          width: 22px;
          transform-origin: top left;
          transition: all 0.2s 0.1s ease;
        }
        &:nth-of-type(5) {
          top: -10px;
          right: -10px;
          transform: rotate(-45deg);
          width: 22px;
          transform-origin: top right;
          transition: all 0.2s 0.2s ease;
        }
      }

      &.nav-open {
        span {
          background: ${colors.white};

          &:nth-of-type(1),
          &:nth-of-type(2),
          &:nth-of-type(3) {
            opacity: 0;
            transition: all 0.4s ease;
          }

          &:nth-of-type(1) {
            left: 8px;
          }
          &:nth-of-type(2) {
            left: -8px;
          }
          &:nth-of-type(3) {
            left: 8px;
          }

          &:nth-of-type(4),
          &:nth-of-type(5) {
            opacity: 1;
          }
          &:nth-of-type(4) {
            top: 0;
            left: 1px;
          }
          &:nth-of-type(5) {
            top: 0;
            right: 0;
          }
        }
      }
    }
`;

  return (
    <Button onClick={handler} aria-label={`menu`}>
      <div className={`lines ${navOpen && "nav-open"}`}>
        <span />
        <span />
        <span />
        <span />
        <span />
      </div>
    </Button>
  );
};

ButtonNavToggle.propTypes = {
  handler: PropTypes.func,
  navOpen: PropTypes.bool,
};

export default ButtonNavToggle;
