import JeanLucThinWOFF from './fonts/jeanlucweb-thin_broad.woff';
import JeanLucBoldWOFF from './fonts/jeanlucweb-bold_broad.woff';
import { fonts } from './themeVars';

// language=SCSS
export default `
  @font-face {
    font-family: 'JeanLuc';
    src: url('${JeanLucThinWOFF}') format("woff");
    font-style: normal;
    font-weight: normal;
  }
  
  @font-face {
    font-family: 'JeanLuc';
    src: url('${JeanLucBoldWOFF}') format("woff");
    font-style: normal;
    font-weight: 700;
  }
  
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6 {
  margin-top: 0;
}

h1,
.h1,
h2,
.h2,
.heading-primary {
  font-size: 30px;
  line-height: 30px;
  font-weight: 700;
  font-family: ${fonts.secondary};

  &.display {
    font-size: 44px;
    line-height: 38px;

    &--same-on-mobile {
      font-size: 52px;
      line-height: 44px;
    }
  }
}
.h3,
.h4,
.h5,
.h6 {
  font-family: ${fonts.primary};
  text-transform: none;
  font-weight: 400;
}
h3, .h3 {
  font-size: 24px;
  line-height: 31px;
}
.h4 {
  font-size: 18px;
  line-height: 24px;
}
.h5 {
  font-size: 20px;
  line-height: 28px;
}
.h6 {
  font-size: 14px;
  line-height: 15px;
  text-transform: uppercase;
  letter-spacing: 1.6px;
  font-weight: 700;
}
`;
