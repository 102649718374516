export function showAlert() {
  this.setState({ visitorAlert: true });
}

export function closeAlert() {
  this.setState({ visitorAlert: false });
}

export function toggleAlert() {
  this.setState({ visitorAlert: !this.state.visitorAlert });
}
