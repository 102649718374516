import styled from "@emotion/styled";
import React from "react";
import { colors } from "../utilities/themeVars";
import { headerVars } from "./Header";
import PropTypes from "prop-types";
import logo from "../images/logo-broad-white.svg";

const NavOverlayContainer = styled.div`
  position: fixed;
  background: ${colors.primary};
  top: 0;
  right: 0;
  bottom: 0;
  left: ${(props) => (props.navOpen ? `0` : `100%`)};
  width: 100%;
  overflow: auto;
  z-index: ${headerVars.zIndex.overlay};
  clip: rect(0px, 100vw, 100vh, 0vw);
  transition: left 0.2s ease;
  -webkit-overflow-scrolling: touch;
`;

const NavOverlayLogo = styled.div`
  position: fixed;
  left: 20px;
  top: 15px;
  width: 123px;
`;

const NavMenuContainer = styled.div`
  margin-top: 135px;
  margin-left: 36px;
`;

const NavOverlay = ({ navOpen, children }) => {
  return (
    <NavOverlayContainer navOpen={navOpen}>
      <NavOverlayLogo>
        <img src={logo} alt="the broad" />
      </NavOverlayLogo>
      <NavMenuContainer>{children}</NavMenuContainer>
    </NavOverlayContainer>
  );
};

NavOverlay.propTypes = {
  navOpen: PropTypes.bool,
  children: PropTypes.node,
};

export default NavOverlay;
