export function clearCurrentTrack() {
  this.setState({
    audioDetails: { ...this.state.audioDetails, url: null, isPlaying: false },
  });
}

export function playTrack() {
  this.setState({
    audioDetails: { ...this.state.audioDetails, isPlaying: true },
  });
}

export function pauseTrack() {
  this.setState({
    audioDetails: { ...this.state.audioDetails, isPlaying: false },
  });
}

export function onAudioInit(payload) {
  this.setState({
    audioDetails: {
      isPlaying: false,
      url: payload.url,
      title: payload.title,
      subTitle: payload.subTitle,
    },
  });
}
