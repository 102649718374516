import { useContext, useEffect } from "react";
import { StoreContext } from "../store";
import PropTypes from "prop-types";

/**
 * Component handles updating the parent route details in store.
 * Instances of component in children will override parent instance.
 * Layout.js instance sets common parent of "Home"
 * Child routes should implement component if require other flow
 *
 * !! set the title prop to 'back' in order to user browser back feature !!
 *
 *
 * @param path
 * @param title
 * @return {null}
 * @constructor
 */
const ParentRouteHandler = ({ path, title }) => {
  const { setParentRoute } = useContext(StoreContext);

  useEffect(() => {
    setParentRoute({
      path: path ? path : "/",
      title: title ? title : "Home",
    });
    return () => {
      setParentRoute(null);
    };
  }, []);

  return null;
};

ParentRouteHandler.propTypes = {
  path: PropTypes.string,
  title: PropTypes.string,
};

ParentRouteHandler.defaultProps = {
  path: null,
  title: null,
};

export default ParentRouteHandler;
