export const colors = {
  primary: '#D6001C',
  text: '#333F48',
  gray: '#f3f3f3',
  gray1: '#333F48',
  gray2: '#D7DBDC',
  gray3: '#E5E7E8',
  gray4: '#9EA3A7',
  gray5: '#F3F3F3',
  white: '#fff',
};

export const fonts = {
  primary: `'Roboto', Helvetica, sans-serif`,
  secondary: `'JeanLuc', Helvetica, sans-serif`,
};

const breakpoints = [768];
export const mq = breakpoints.map(bp => `@media (min-width: ${bp}px)`);
