import { SessionStorageService } from '../../services/session-storage';

export function markArtExplored(id, formState) {
  this.setState(
    {
      exploredArt: {
        ...this.state.exploredArt,
        [id]: formState,
      },
    },
    () => {
      SessionStorageService.setValue(
        'broad_exploredArt',
        this.state.exploredArt
      );
    }
  );
}

export function setExploredArt(exploredArt) {
  this.setState({
    exploredArt,
  });
}
