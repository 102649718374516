import { StoreContext, StoreProvider } from './src/store/index';
import React, { useContext } from 'react';
import { updateRouteDetails } from './src/store/methods/routeTracker';

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// This is causing issues with css loading on production builds
// switched to gatsby-plugin-layout to resolve
// export const wrapPageElement = ({ element, props }) => {
//   return (
//     <>
//       <Layout {...props}>{element}</Layout>
//     </>
//   );
// };

export const wrapRootElement = ({ element }) => (
  <StoreProvider>{element}</StoreProvider>
);

// maybe useful for GA
export const onRouteUpdate = ({ location, prevLocation }) => {
  // console.log('new pathname', location.pathname);
  // console.log('old pathname', prevLocation ? prevLocation.pathname : null);
};

// Disabling Gatsby's default scroll handling behavior
// because only saved scroll position when using browser "back" button
//
// Handling in ManageScroll.js instead
export const shouldUpdateScroll = () => {
  return false;
};
