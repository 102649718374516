import { SessionStorageService } from "../../services/session-storage";

export function markScavenger(id, formState) {
  this.setState(
    {
      scavengerHunt: {
        ...this.state.scavengerHunt,
      },
    },
    () => {
      SessionStorageService.setValue(
        "broad_scavengerHunt",
        this.state.scavengerHunt
      );
    }
  );
}

export function setScavengerState(scavengerHunt) {
  this.setState({
    scavengerHunt,
  });
}
