export function showNavBar() {
  this.setState({ navBarOpen: true });
}

export function closeNavBar() {
  this.setState({ navBarOpen: false });
}

export function toggleNavBar() {
  this.setState({ navBarOpen: !this.state.navBarOpen });
}
