import React from "react";
import styled from "@emotion/styled";
import ReactPlayer from "react-player";
import Slider from "rc-slider/lib/Slider";
import "rc-slider/assets/index.css";
import { colors } from "../utilities/themeVars";
import playIcon from "../images/icon-play-button-white.svg";
import pauseIcon from "../images/icon-pause.svg";
import Duration from "./Duration";
import { StoreContext } from "../store";

const PlayerWrapper = styled.div`
  position: fixed;
  padding: 10px 20px;
  height: 112px;
  background: ${colors.text};
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
`;

const PlayerTop = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  button {
    width: 42px;
    height: 42px;
    padding: 0;
    outline: none;
    margin-right: 15px;
  }

  .track-title {
    font-size: 14px;
    margin-bottom: 3px;
    color: ${colors.white};
  }

  .track-subtitle {
    color: ${colors.primary};
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
  }
`;

const PlayerSlider = styled.div`
  .time-details {
    display: flex;
    justify-content: space-between;
    color: ${colors.white};
    font-size: 12px;
    margin-top: 5px;
  }
  .rc-slider-rail {
    background-color: ${colors.white};
    height: 10px;
    border-radius: 0;
  }
  .rc-slider-track {
    height: 10px;
    background: ${colors.primary};
    border-radius: 0;
  }
  .rc-slider-handle {
    background: ${colors.primary};
    border-radius: 0;
    border: none;
    margin-left: -3px;
    margin-top: 0px;
    width: 10px;
    height: 10px;
  }
`;

const CloseBtnStyles = styled.button`
  bottom: 40px;
  position: fixed;
  right: 0;
  z-index: 9;

  .close {
    bottom: 40px;
    position: absolute;
    right: 20px;
    width: 15px;
    height: 15px;
    color: #fff;
  }
  .close:hover {
    opacity: 1;
  }
  .close:before,
  .close:after {
    color: #fff;
    position: absolute;
    content: " ";
    height: 15px;
    width: 2px;
    background-color: #fff;
  }
  .close:before {
    color: #fff;
    transform: rotate(45deg);
  }
  .close:after {
    color: #fff;
    transform: rotate(-45deg);
  }
`;

class Player extends React.Component {
  state = {
    isPlaying: false,
    played: 0,
    loaded: 0,
    duration: 0,
    loop: false,
    sliderTracking: 0,
  };

  constructor(props) {
    super(props);
    this.player = React.createRef();
    this.playButton = React.createRef();
    this.invisiblePlayer = React.createRef();
  }

  playPause = () => {
    if (this.state.isPlaying) {
      this.setState({
        isPlaying: false,
      });
    } else {
      this.setState({
        isPlaying: true,
      });
    }

    if (this.context.audioDetails.isPlaying) {
      this.context.pauseTrack();
    } else {
      this.context.playTrack();
    }
  };

  stop = (e) => {
    e.preventDefault();
    this.context.clearCurrentTrack();
  };

  onSeekMouseDown = (e) => {
    this.setState({ seeking: true });
  };
  onSeekChange = (e) => {
    // this.setState({ played: parseFloat(e.target.value) });
  };
  onSeekMouseUp = (val) => {
    this.setState({ seeking: false });
    this.player.seekTo(parseFloat(val));
  };
  onProgress = (state) => {
    // We only want to update time slider if we are not currently seeking
    if (!this.state.seeking) {
      this.setState({ sliderTracking: state.playedSeconds });
      this.setState(state);
    }
  };

  onDuration = (duration) => {
    // this.setState({ duration });
  };

  // onEnded = () => {
  //   this.setState({ playing: this.state.loop });
  // };

  componentDidMount() {
    const url = `${window.location.origin}${this.props.url}`;
    setTimeout(() => {
      const audio = new Audio();
      audio.src = url;

      audio.oncanplay = () => {
        console.log("Invisible player loaded");
        console.log(audio.duration);

        this.setState({
          duration: audio.duration,
        });
      };

      audio.load();

      this.playButton.click();
    }, 500);
  }

  render() {
    const { played, duration, sliderTracking, isPlaying } = this.state;
    const { url, title, subTitle } = this.props;

    return (
      <>
        {url && (
          <div>
            <audio
              ref={(audio) => {
                this.invisiblePlayer = audio;
              }}
            />

            <div className="player-wrapper">
              <ReactPlayer
                ref={(rp) => (this.player = rp)}
                className="react-player"
                width="0"
                height="0"
                playing={isPlaying}
                url={url}
                onStart={() => console.log()}
                onReady={() => console.log("Audio Ready")}
                onBuffer={() => console.log()}
                onSeek={(e) => console.log()}
                onEnded={this.onEnded}
                onError={(e) => console.log()}
                onProgress={this.onProgress}
                onDuration={this.onDuration}
              />
            </div>

            <PlayerWrapper>
              <PlayerTop>
                <button
                  ref={(button) => (this.playButton = button)}
                  onClick={this.playPause}
                >
                  <img
                    src={isPlaying ? pauseIcon : playIcon}
                    alt={isPlaying ? "play" : "pause"}
                  />
                </button>
                <div>
                  <div className="track-title">{title}</div>
                  {/*<div className="track-subtitle">{subTitle}</div>*/}
                </div>
              </PlayerTop>

              <PlayerSlider>
                <Slider
                  max={Math.ceil(duration)}
                  value={sliderTracking}
                  onReady={() => console.log("onReady")}
                  onBeforeChange={(value) => this.onSeekMouseDown(value)}
                  onChange={(value) => this.setState({ sliderTracking: value })}
                  onAfterChange={(value) => this.onSeekMouseUp(value)}
                />
                <div className={"time-details"}>
                  <Duration seconds={duration * played} />
                  <Duration seconds={duration} />
                </div>
              </PlayerSlider>
            </PlayerWrapper>

            <CloseBtnStyles onClick={(e) => this.stop(e)}>
              <a href="" className="close" role="button" aria-label="next"></a>
              <i aria-hidden="true"></i> {/* </a> */}
            </CloseBtnStyles>
          </div>
        )}
      </>
    );
  }
}

Player.contextType = StoreContext;

export default Player;
