import React, { useContext } from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import styled from "@emotion/styled";
import { StoreContext } from "../store";

const VisitorAlert = () => {
  const { visitorAlert, toggleAlert } = useContext(StoreContext);

  const Container = styled.div`
    align-items: center;
    background-color: #333f48;
    display: flex;
    justify-content: center;
    left: 0;
    padding: 0.5rem 1rem;
    position: fixed;
    right: 0;
    top: 0;

    a {
      color: #fff;
      max-width: 80%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  `;

  const Text = styled.p`
    color: #fff;
    font-size: 12px;
    margin: 0;
  `;

  const Close = styled.span`
    cursor: pointer;
    height: 15px;
    position: absolute;
    right: 32px;
    top: 50%;
    transform: translateY(-50%);
    width: 15px;

    &::before,
    &::after {
      background-color: #fff;
      content: " ";
      left: 15px;
      height: 15px;
      position: absolute;
      width: 2px;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  `;

  const { alerts } = useStaticQuery(
    graphql`
      query {
        alerts: allNodeVisitorAlert {
          nodes {
            title
            field_short_title
            field_link {
              url
            }
            field_show_more_link
          }
        }
      }
    `
  );

  const toggleClick = () => {
    toggleAlert();
  };

  return !!(
    alerts.nodes.length &&
    alerts.nodes[0].field_short_title &&
    visitorAlert
  ) ? (
    <Container>
      {alerts.nodes[0].field_show_more_link &&
      alerts.nodes[0].field_link.url ? (
        <Link to={alerts.nodes[0].field_link.url}>
          {alerts.nodes[0].field_short_title}
        </Link>
      ) : (
        <Text>{alerts.nodes[0].field_short_title}</Text>
      )}
      <Close
        onClick={() => {
          toggleClick();
        }}
      />
    </Container>
  ) : (
    <></>
  );
};

export default VisitorAlert;
