import { Link } from "gatsby";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import styled from "@emotion/styled";
import { colors } from "../utilities/themeVars";
import logo from "../images/logo-broad-red.svg";
import mapIcon from "../images/map-icon.svg";
import ButtonBack from "./ButtonBack";
import { StoreContext } from "../store";
import { customBackHandler } from "../utilities/helpers";
import VisitorAlert from "./VisitorAlert";
import { graphql, useStaticQuery } from "gatsby";

export const headerVars = {
  height: `63px`,
  zIndex: {
    header: 5,
    overlay: 6,
    toggle: 7,
  },
};

const HeaderInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding-left: 20px;
  padding-right: 60px;
`;

const Right = styled.div``;
const Left = styled.div``;

const Logo = styled((props) => <Link {...props} />)`
  display: inline-block;
  width: 123px;
`;

const Header = ({ location }) => {
  const { parentRouteDetails, visitorAlert } = useContext(StoreContext);

  // if (typeof window !== "undefined" && window.document) {
  //   console.log("header", visitorAlertHeight);
  // }

  const { alerts } = useStaticQuery(
    graphql`
      query {
        alerts: allNodeVisitorAlert {
          nodes {
            title
            field_short_title
          }
        }
      }
    `
  );

  const HeaderContainer = styled.div`
    position: fixed;
    top: ${alerts.nodes.length && visitorAlert ? "26px;" : "0;"}
    right: 0;
    left: 0;
    height: ${headerVars.height};
    background: ${colors.white};
    z-index: ${headerVars.zIndex.header};
    border-bottom: solid 1px ${colors.gray3};
  `;

  return (
    <HeaderContainer>
      <VisitorAlert />
      <HeaderInner>
        <Left>
          {location.pathname === "/" ? (
            <Logo to="/">
              <img src={logo} alt="the broad" />
            </Logo>
          ) : (
            <ButtonBack
              handler={() => customBackHandler(parentRouteDetails)}
              prevTitle={parentRouteDetails.title}
            />
          )}
        </Left>
        <Right>
          <Link to={`/map`}>
            <img src={mapIcon} alt="map" />
          </Link>
        </Right>
      </HeaderInner>
    </HeaderContainer>
  );
};

Header.propTypes = {
  location: PropTypes.objectOf(PropTypes.any),
};

export default Header;
