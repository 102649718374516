import React from 'react';
import { Location } from '@reach/router';

let scrollPositions = {};

class ManageScrollImpl extends React.Component {
  componentDidMount() {
    try {
      // session storage will throw for a few reasons
      // - user settings
      // - in-cognito/private browsing
      // - who knows...
      let storage = JSON.parse(sessionStorage.getItem('scrollPositions'));
      if (storage) {
        scrollPositions = storage || {};

        const { pathname } = this.props.location;
        const savedPath = this.removeTrailingSlash(pathname);

        if (scrollPositions[savedPath]) {
          window.scrollTo(0, scrollPositions[savedPath]);
        }
      }
    } catch (e) {
      console.log(e);
    }

    window.addEventListener('scroll', this.listener);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listener);
  }

  componentDidUpdate() {
    window.removeEventListener('scroll', this.listener);
    const { pathname } = this.props.location;
    const savedPath = this.removeTrailingSlash(pathname);

    setTimeout(() => {
      if (!scrollPositions[savedPath]) {
        // never seen this location before
        window.scrollTo(0, 0);
      } else {
        window.scrollTo(0, scrollPositions[savedPath]);
      }
    }, 0);

    setTimeout(() => {
      window.addEventListener('scroll', this.listener);
    }, 100);
  }

  removeTrailingSlash = pathname => {
    let savedPath = pathname;

    if (pathname === '/') {
      savedPath = 'homePath';
    } else if (pathname.endsWith('/')) {
      savedPath = pathname.slice(0, -1);
    }

    return savedPath;
  };

  listener = () => {
    const savedPath = this.removeTrailingSlash(this.props.location.pathname);

    scrollPositions[savedPath] = window.scrollY;
    try {
      sessionStorage.setItem(
        'scrollPositions',
        JSON.stringify(scrollPositions)
      );
    } catch (e) {}
  };

  render() {
    return null;
  }
}

export default () => (
  <Location>
    {({ location }) => <ManageScrollImpl location={location} />}
  </Location>
);
