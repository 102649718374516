import React, { PureComponent } from 'react';
import initialState from './initialState';
import storeMethods from './methods';

export const store = {
  ...initialState,
  ...storeMethods,
};

export const StoreContext = React.createContext(store);

export class StoreProvider extends React.Component {
  constructor(props) {
    super(props);

    const boundStore = Object.keys(store)
      .map(key => {
        if (typeof store[key] === 'function') {
          return { key: key, value: store[key].bind(this) };
        }
        return { key: key, value: store[key] };
      })
      .reduce((a, b) => {
        a[b.key] = b.value;
        return { ...a };
      }, {});

    this.state = boundStore;
  }

  render() {
    // console.log(this.state);
    return (
      <StoreContext.Provider value={this.state}>
        {this.props.children}
      </StoreContext.Provider>
    );
  }
}
