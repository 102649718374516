export function setParentRoute(config) {
  if (!config) {
    this.setState({
      parentRouteDetails: {
        path: '/',
        title: 'Home',
      },
    });
  } else {
    this.setState({
      parentRouteDetails: {
        path: config.path,
        title: config.title,
      },
    });
  }
}
