import { navigate } from 'gatsby';

/**
 * Remove "mobile app" from string
 * @param name
 * @return {string}
 */
export const replacedTermName = name =>
  name
    .toLowerCase()
    .replace('mobile app', '')
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

// there is a reason why we don't use history API,
// One example is, you are on inner page, i.e. some artwork page,
// and then you hit menu button, and select a page from there.
// The back button should take you to whatever is in label (probably HOME), but
// instead takes you back to artwork page
/**
 * Take user back to "parent" route, unless the label is "Back", in which case go
 * back 1
 * @param parentRouteDetails
 * @returns {Promise<void>|void}
 */
export const customBackHandler = parentRouteDetails => {
  if (parentRouteDetails.title.toLowerCase() === 'back') {
    return window.history.go(-1);
  }
  return navigate(parentRouteDetails.path);
};
