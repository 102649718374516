import React, { useContext } from "react";
import { Global, css } from "@emotion/react";
import normalize from "../utilities/normalize";
import fonts from "../utilities/fonts";
import { colors } from "../utilities/themeVars";
import { StoreContext } from "../store";

const NonDynamicGlobalStyles = React.memo(() => {
  return (
    <Global
      styles={css`
        ${normalize};
        ${fonts};
      `}
    />
  );
});

const GlobalStyles = ({ location }) => {
  const { isGrid, collectionsViewState, audioDetails } = useContext(
    StoreContext
  );
  const { url: trackUrl } = audioDetails;

  let chatOffsetBottom = 0;

  let backgroundColor = colors.white;
  if (
    (location.pathname.indexOf("audio-tour") !== -1 && isGrid) ||
    (location.pathname.indexOf("collection") !== -1 &&
      (collectionsViewState === "grid" || collectionsViewState === "fun facts"))
  ) {
    backgroundColor = colors.gray5;
  }

  if (
    location.pathname.indexOf("/art/") !== -1 ||
    location.pathname.indexOf("/explore/") !== -1 ||
    location.pathname.indexOf("/tour-stops/") !== -1 ||
    location.pathname.indexOf("/reader") !== -1
  ) {
    if (trackUrl) {
      chatOffsetBottom += 150;
    } else {
      chatOffsetBottom = 50;
    }
  } else if (trackUrl) {
    chatOffsetBottom += 110;
  }

  return (
    <>
      <NonDynamicGlobalStyles />
      <Global
        styles={css`
          html,
          body {
            background: ${backgroundColor};
          }

          iframe#launcher {
            bottom: ${chatOffsetBottom}px !important;
          }
        `}
      />
    </>
  );
};

export default GlobalStyles;
