import React from "react";
import styled from "@emotion/styled";
import arrow from "../images/back-arrow.svg";
import { fonts } from "../utilities/themeVars";

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  .prev-title {
    line-height: 1;
    margin-left: 15px;
    font-weight: bold;
    font-family: ${fonts.secondary};
    font-size: 20px;
  }
`;

const ButtonBack = ({ handler, prevTitle }) => {
  return (
    <Button onClick={handler} aria-label={`Go Back`}>
      <img src={arrow} alt="back arrow" />
      <div className={`prev-title`}>{prevTitle}</div>
    </Button>
  );
};

export default ButtonBack;
